window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.scholarship_labels = window.locales.am.scholarship_labels || {};window.locales.am.scholarship_labels = {...window.locales.am.scholarship_labels, ...{
    "entrepreneurship": "የ Hyperion ሽልማት ለኢንተርፕሪነሮች",           
    "entrepreneurship_short": "Hyperion",           
    "entrepreneurship_desc": "ጉጉት ላላቸው እና ለወቅታዊ ኢንተርፕርነሮች የትምህርት ክፍያ እርዳታ የሚያደርግ ስኮላርሺፕ።",           
    "emerging_leaders": "Strada ስኮላርሺፕ ለአዳዲስ መሪዎች",           
    "emerging_leaders_short": "Strada",           
    "emerging_leaders_desc": "ብዙ ጊዜ በታዳጊ ኢንዱስትሪ ወይም ሃገር ውስጥ የሚሰሩ ተስፋ ሰጪ የመሪነት ችሎታ ላላቸው ተማሪዎች ሽልማት ይሰጣል።",           
    "sustainability": "Powell Impact and Sustainability ስኮላርሺፕ",           
    "sustainability_short": "Powell",           
    "sustainability_desc": "የማህበረሰባቸውን ደህንነት ወይም የአለምን ሁኔታ ለማሻሻል ለሚሰሩ ሰዎች።",           
    "women_in_tech": "በቴክ ውስጥ ላሉ ሴቶች ያለ የመሪነት ሽልማት",           
    "women_in_tech_short": "አመራር",           
    "women_in_tech_desc": "ልዩ የመሪነት ብቃቶች ላላቸው ንቁ የቴክኖሎጂ ባለሙያዎች እና አስፈጻሚዎች ሽልማት ይሰጣል።",           
    "academic_excellence": "ለአካዳሚክ ልህቀት የ Zenith ሽልማት",           
    "academic_excellence_short": "Zenith",           
    "academic_excellence_desc": "በጥናታቸው ውስጥ ሁሉ ልዩ የሆነ የአካዳሚክ ብቃታቸውን ላሳዩ ሰዎች የሚሰጥ።",           
    "innovation_sponsorship_award": "የኢኖቬሽን ስፖንሰርሺፕ ሽልማት",
    "innovation_sponsorship_award_short": "ፈጠራ",
    "edcor_award": "የኤድኮር ሽልማት",
    "edcor_award_short": "ኢድኮር",
    "global_health_impact_network": "ዓለም አቀፍ የጤና ተጽዕኖ አውታረ መረብ",
    "global_health_impact_network_short": "GHIN",
    "employer_partner": "የአሰሪ አጋር ስኮላርሺፕ",
    "employer_partner_short": "አሰሪ አጋር",
    "recruitment": "የቅጥር ስኮላርሺፕ",
    "recruitment_short": "ምልመላ",
    "strada": "የስትራዳ አመራር ስኮላርሺፕ",
    "strada_short": "የስትራዳ አመራር",
    "impact_quantic": "የኳንቲክ ተፅእኖ ስኮላርሺፕ",
    "impact_quantic_short": "የኳንቲክ ተጽእኖ",
    "powell": "Powell Aid ስኮላርሺፕ",
    "powell_short": "ፓውል እርዳታ",
    "impact_valar": "የቫላር ኢምፓክት ስኮላርሺፕ",
    "impact_valar_short": "የቫላር ተፅዕኖ",
    "hyperion": "የ Hyperion Aid ስኮላርሺፕ",
    "hyperion_short": "ሃይፐርዮን እርዳታ",
    "base_scholarship": "ስኮላርሺፕ",
    "strada_application": "የአመራር ስኮላርሺፕ",
    "impact_quantic_application": "ተፅዕኖ ስኮላርሺፕ",
    "powell_application": "የገንዘብ እርዳታ ስኮላርሺፕ",
    "impact_valar_application": "ተፅዕኖ ስኮላርሺፕ",
    "employer_partner_application": "የአሰሪ አጋር ስኮላርሺፕ",
    "recruitment_application": "የቅጥር ስኮላርሺፕ",
    "hyperion_application": "የገንዘብ እርዳታ ስኮላርሺፕ",
    "strada_female": "የስትራዳ ሴቶች በአመራር ስኮላርሺፕ",
    "strada_female_short": "Strada ሴቶች አመራር ውስጥ",
    "exec_ed_launch": "Exec Ed ማስጀመሪያ ስኮላርሺፕ",
    "exec_ed_launch_short": "Exec Ed ማስጀመሪያ ስኮላርሺፕ",
    "alumni": "የተመራቂ ተማሪዎች ስኮላርሺፕ",
    "alumni_application": "የተመራቂ ተማሪዎች ስኮላርሺፕ",
    "zenith": "የአመራር ስኮላርሺፕ",
    "zenith_short": "አመራር",
    "zenith_application": "የአመራር ስኮላርሺፕ",
    "impact_global": "ዓለም አቀፍ ተጽዕኖ ስኮላርሺፕ",
    "alumni_quantic_launch": "የኳንቲክ ማስጀመሪያ ስኮላርሺፕ"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.scholarship_labels = window.locales.ar.scholarship_labels || {};window.locales.ar.scholarship_labels = {...window.locales.ar.scholarship_labels, ...{
    "entrepreneurship": "جائزة هايبريون لرواد الأعمال",
    "emerging_leaders": "منحة سترادا للقادة الناشئين",
    "sustainability": "منحة باول للتأثير والاستدامة",
    "women_in_tech": "جائزة القيادة للمرأة في التكنولوجيا",
    "academic_excellence": "جائزة زينيث للتفوق الأكاديمي",
    "entrepreneurship_desc": "منحة دراسية تقدم المساعدة في الرسوم الدراسية لأصحاب المشاريع الطموحين والمتمرسين.",
    "sustainability_desc": "لأولئك الذين يعملون على تحسين رفاهية مجتمعهم أو حالة العالم.",
    "women_in_tech_desc": "تُمنح لمتخصصي التكنولوجيا النشطين والمديرين التنفيذيين الذين يتمتعون بصفات قيادية استثنائية.",
    "academic_excellence_desc": "تُمنح لأولئك الذين أظهروا أداءً أكاديميًا استثنائيًا خلال دراستهم.",
    "emerging_leaders_desc": "تُمنح للطلاب ذوي القدرات القيادية الواعدة ، الذين يعملون غالبًا في صناعة أو دولة ناشئة.",
    "entrepreneurship_short": "هايبريون",
    "emerging_leaders_short": "شارع",
    "sustainability_short": "باول",
    "women_in_tech_short": "قيادة",
    "academic_excellence_short": "زينيث",
    "innovation_sponsorship_award": "جائزة رعاية الابتكار",
    "innovation_sponsorship_award_short": "ابتكار",
    "edcor_award": "جائزة Edcor",
    "edcor_award_short": "إدكور",
    "global_health_impact_network": "شبكة التأثير الصحي العالمية",
    "global_health_impact_network_short": "GHIN",
    "employer_partner": "منحة شريك صاحب العمل",
    "employer_partner_short": "صاحب العمل الشريك",
    "recruitment": "منحة التوظيف",
    "recruitment_short": "توظيف",
    "strada": "منحة القيادة Strada",
    "strada_short": "قيادة سترادا",
    "impact_quantic": "منحة كوانتيك للتأثير",
    "impact_quantic_short": "تأثير كوانتيك",
    "powell": "منحة باول ايد",
    "powell_short": "مساعدة باول",
    "impact_valar": "منحة فالار امباكت",
    "impact_valar_short": "تأثير فالار",
    "hyperion": "منحة هايبريون ايد",
    "hyperion_short": "هايبريون ايد",
    "base_scholarship": "منحة دراسية",
    "strada_application": "منحة القيادة",
    "impact_quantic_application": "منحة التأثير",
    "powell_application": "منحة المساعدات المالية",
    "impact_valar_application": "منحة التأثير",
    "employer_partner_application": "منحة شريك صاحب العمل",
    "recruitment_application": "منحة التوظيف",
    "hyperion_application": "منحة المساعدات المالية",
    "strada_female": "منحة سترادا للمرأة في القيادة",
    "strada_female_short": "نساء سترادا في القيادة",
    "exec_ed_launch": "منحة إطلاق Exec Ed",
    "exec_ed_launch_short": "منحة إطلاق Exec Ed",
    "alumni": "منحة الخريجين",
    "alumni_application": "منحة الخريجين",
    "zenith": "منحة القيادة",
    "zenith_short": "قيادة",
    "zenith_application": "منحة القيادة",
    "impact_global": "منحة التأثير العالمي",
    "alumni_quantic_launch": "منحة إطلاق Quantic"
}
};window.locales.en = window.locales.en || {};window.locales.en.scholarship_labels = window.locales.en.scholarship_labels || {};window.locales.en.scholarship_labels = {...window.locales.en.scholarship_labels, ...{
                                                                                       
    "employer_partner": "Employer Partner Scholarship",
    "employer_partner_short": "Employer Partner",
    "recruitment": "Recruitment Scholarship",
    "recruitment_short": "Recruitment",
    "strada": "Strada Leadership Scholarship",
    "strada_short": "Strada Leadership",
    "strada_female": "Strada Women in Leadership Scholarship",
    "strada_female_short": "Strada Women in Leadership",
    "impact_quantic": "Quantic Impact Scholarship",
    "impact_quantic_short": "Quantic Impact",
    "powell": "Powell Aid Scholarship",
    "powell_short": "Powell Aid",
    "zenith": "Leadership Scholarship",
    "zenith_short": "Leadership",
    "impact_valar": "Valar Impact Scholarship",
    "impact_valar_short": "Valar Impact",
    "hyperion": "Hyperion Aid Scholarship",
    "hyperion_short": "Hyperion Aid",
    "exec_ed_launch": "Exec Ed Launch Scholarship",
    "exec_ed_launch_short": "Exec Ed Launch Scholarship",
    "alumni": "Alumni Scholarship",
    "impact_global": "Global Impact Scholarship",
    "alumni_quantic_launch": "Quantic Launch Scholarship",

    "employer_partner_application": "Employer Partner Scholarship",
    "recruitment_application": "Recruitment Scholarship",
    "strada_application": "Leadership Scholarship",
    "impact_quantic_application": "Impact Scholarship",
    "powell_application": "Financial Aid Scholarship",
    "zenith_application": "Leadership Scholarship",
    "impact_valar_application": "Impact Scholarship",
    "hyperion_application": "Financial Aid Scholarship",
    "alumni_application": "Alumni Scholarship",

                                                
    "entrepreneurship": "Hyperion Award for Entrepreneurs",
    "entrepreneurship_short": "Hyperion",
    "entrepreneurship_desc": "Scholarship that provides tuition assistance for aspiring and seasoned entrepreneurs.",
    "emerging_leaders": "Strada Scholarship for Emerging Leaders",
    "emerging_leaders_short": "Strada",
    "emerging_leaders_desc": "Awarded to students with promising leadership ability, who often work in an emerging industry or nation.",
    "sustainability": "Powell Impact and Sustainability Scholarship",
    "sustainability_short": "Powell",
    "sustainability_desc": "For those working to improve the wellbeing of their community or the state of the world.",
    "women_in_tech": "Leadership Award for Women in Tech",
    "women_in_tech_short": "Leadership",
    "women_in_tech_desc": "Awarded to active technology professionals and executives with exceptional leadership qualities.",
    "academic_excellence": "The Zenith Award for Academic Excellence",
    "academic_excellence_short": "Zenith",
    "academic_excellence_desc": "Given to those who have demonstrated exceptional academic performance throughout their studies.",

                                                                                                        
                                                                                                              
                                                                                                                
    "innovation_sponsorship_award": "Innovation Sponsorship Award",
    "innovation_sponsorship_award_short": "Innovation",
    "edcor_award": "Edcor Award",
    "edcor_award_short": "Edcor",
    "global_health_impact_network": "Global Health Impact Network",
    "global_health_impact_network_short": "GHIN",

    "base_scholarship": "Scholarship"
}
};window.locales.es = window.locales.es || {};window.locales.es.scholarship_labels = window.locales.es.scholarship_labels || {};window.locales.es.scholarship_labels = {...window.locales.es.scholarship_labels, ...{
    "entrepreneurship": "Premio Hyperion para emprendedores",
    "emerging_leaders": "Beca Strada para líderes emergentes",
    "sustainability": "Beca Powell de Impacto y Sostenibilidad",
    "women_in_tech": "Premio al liderazgo para mujeres en tecnología",
    "academic_excellence": "El premio Zenith a la excelencia académica",
    "entrepreneurship_desc": "Beca que brinda asistencia para la matrícula para emprendedores aspirantes y experimentados.",
    "sustainability_desc": "Para quienes trabajan para mejorar el bienestar de su comunidad o el estado del mundo.",
    "women_in_tech_desc": "Otorgado a profesionales y ejecutivos de tecnología activos con cualidades de liderazgo excepcionales.",
    "academic_excellence_desc": "Otorgado a quienes hayan demostrado un rendimiento académico excepcional a lo largo de sus estudios.",
    "emerging_leaders_desc": "Otorgado a estudiantes con una capacidad de liderazgo prometedora, que a menudo trabajan en una industria o nación emergente.",
    "entrepreneurship_short": "Hyperion",
    "emerging_leaders_short": "calle",
    "sustainability_short": "Powell",
    "women_in_tech_short": "Liderazgo",
    "academic_excellence_short": "Cenit",
    "innovation_sponsorship_award": "Premio Patrocinio Innovación",
    "innovation_sponsorship_award_short": "Innovación",
    "edcor_award": "Premio Edcor",
    "edcor_award_short": "Edcor",
    "global_health_impact_network": "Red Global de Impacto en la Salud",
    "global_health_impact_network_short": "GIN",
    "employer_partner": "Beca Socio Empleador",
    "employer_partner_short": "Socio empleador",
    "recruitment": "Beca de Reclutamiento",
    "recruitment_short": "Reclutamiento",
    "strada": "Beca de Liderazgo Strada",
    "strada_short": "Liderazgo Strada",
    "impact_quantic": "Beca de impacto cuántico",
    "impact_quantic_short": "Impacto cuántico",
    "powell": "Beca de ayuda Powell",
    "powell_short": "Ayuda Powell",
    "impact_valar": "Beca de impacto Valar",
    "impact_valar_short": "Impacto Valar",
    "hyperion": "Beca de ayuda Hyperion",
    "hyperion_short": "Ayuda Hiperión",
    "base_scholarship": "Beca",
    "strada_application": "Beca de Liderazgo",
    "impact_quantic_application": "Beca de impacto",
    "powell_application": "Beca de ayuda financiera",
    "impact_valar_application": "Beca de impacto",
    "employer_partner_application": "Beca Socio Empleador",
    "recruitment_application": "Beca de Reclutamiento",
    "hyperion_application": "Beca de ayuda financiera",
    "strada_female": "Beca Strada Mujeres en Liderazgo",
    "strada_female_short": "Strada Mujeres en el Liderazgo",
    "exec_ed_launch": "Beca de lanzamiento de educación ejecutiva",
    "exec_ed_launch_short": "Beca de lanzamiento de educación ejecutiva",
    "alumni": "Beca de antiguos alumnos",
    "alumni_application": "Beca de antiguos alumnos",
    "zenith": "Beca de liderazgo",
    "zenith_short": "Liderazgo",
    "zenith_application": "Beca de liderazgo",
    "impact_global": "Beca de Impacto Global",
    "alumni_quantic_launch": "Beca Quantic Launch"
}
};window.locales.it = window.locales.it || {};window.locales.it.scholarship_labels = window.locales.it.scholarship_labels || {};window.locales.it.scholarship_labels = {...window.locales.it.scholarship_labels, ...{
    "entrepreneurship": "Premio Hyperion per imprenditori",
    "emerging_leaders": "Borsa di studio Strada per leader emergenti",
    "sustainability": "Borsa di studio Powell Impact and Sustainability",
    "women_in_tech": "Premio Leadership per Women in Tech",
    "academic_excellence": "Il premio Zenith per l'eccellenza accademica",
    "entrepreneurship_desc": "Borsa di studio che fornisce assistenza didattica per aspiranti e stagionati imprenditori.",
    "sustainability_desc": "Per coloro che lavorano per migliorare il benessere della propria comunità o lo stato del mondo.",
    "women_in_tech_desc": "Conferito a professionisti e dirigenti tecnologici attivi con eccezionali qualità di leadership.",
    "academic_excellence_desc": "Dato a coloro che hanno dimostrato prestazioni accademiche eccezionali durante i loro studi.",
    "emerging_leaders_desc": "Assegnato a studenti con promettenti capacità di leadership, che spesso lavorano in un settore o nazione emergente.",
    "entrepreneurship_short": "Hyperion",
    "emerging_leaders_short": "Strada",
    "sustainability_short": "Powell",
    "women_in_tech_short": "Comando",
    "academic_excellence_short": "Zenit",
    "innovation_sponsorship_award": "Premio per la sponsorizzazione dell'innovazione",
    "innovation_sponsorship_award_short": "Innovazione",
    "edcor_award": "Premio Edcor",
    "edcor_award_short": "Edcor",
    "global_health_impact_network": "Rete globale di impatto sulla salute",
    "global_health_impact_network_short": "GHIN",
    "employer_partner": "Borsa di studio per datori di lavoro",
    "employer_partner_short": "Socio datore di lavoro",
    "recruitment": "Borsa di studio per il reclutamento",
    "recruitment_short": "Reclutamento",
    "strada": "Borsa di studio per la leadership di Strada",
    "strada_short": "Direzione Stradale",
    "impact_quantic": "Borsa di studio sull'impatto quantico",
    "impact_quantic_short": "Impatto quantico",
    "powell": "Borsa di studio Powell Aid",
    "powell_short": "Aiuto Powell",
    "impact_valar": "Borsa di studio Valar Impact",
    "impact_valar_short": "Impatto Valar",
    "hyperion": "Borsa di studio Hyperion Aid",
    "hyperion_short": "Hyperion Aiuto",
    "base_scholarship": "Borsa di studio",
    "strada_application": "Borsa di studio per la leadership",
    "impact_quantic_application": "Borsa di studio sull'impatto",
    "powell_application": "Borsa di studio per aiuti finanziari",
    "impact_valar_application": "Borsa di studio sull'impatto",
    "employer_partner_application": "Borsa di studio per datori di lavoro",
    "recruitment_application": "Borsa di studio per il reclutamento",
    "hyperion_application": "Borsa di studio per aiuti finanziari",
    "strada_female": "Borsa di studio Women in Leadership Strada",
    "strada_female_short": "Strada Donne al comando",
    "exec_ed_launch": "Borsa di studio per il lancio di Exec Ed",
    "exec_ed_launch_short": "Borsa di studio per il lancio di Exec Ed",
    "alumni": "Borsa di studio per ex studenti",
    "alumni_application": "Borsa di studio per ex studenti",
    "zenith": "Borsa di studio sulla leadership",
    "zenith_short": "Comando",
    "zenith_application": "Borsa di studio sulla leadership",
    "impact_global": "Borsa di studio ad impatto globale",
    "alumni_quantic_launch": "Borsa di studio Quantic Launch"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.scholarship_labels = window.locales.zh.scholarship_labels || {};window.locales.zh.scholarship_labels = {...window.locales.zh.scholarship_labels, ...{
    "entrepreneurship": "亥伯龙企业家奖",           
    "emerging_leaders": "斯特拉达新兴领袖奖学金",           
    "sustainability": "鲍威尔影响和可持续发展奖学金",           
    "women_in_tech": "科技界女性领袖奖",           
    "academic_excellence": "齐尼思学术卓越奖",           
    "entrepreneurship_desc": "授予有抱负和经验丰富的企业家提供学费资助的奖学金。",           
    "sustainability_desc": "授予那些致力于改善社区福祉或世界状况的人。",           
    "women_in_tech_desc": "授予具有杰出领导力的积极技术专业人士和高管。",           
    "academic_excellence_desc": "授予在整个学习过程中表现优异的学员。",           
    "emerging_leaders_desc": "授予在新兴行业或国家工作并具有较强领导能力的学员。",           
    "entrepreneurship_short": "亥伯龙",           
    "emerging_leaders_short": "斯特拉达",           
    "sustainability_short": "鲍威尔",           
    "women_in_tech_short": "领导力",           
    "academic_excellence_short": "齐尼思",           
    "innovation_sponsorship_award": "创新赞助奖",
    "innovation_sponsorship_award_short": "革新",
    "edcor_award": "Edcor 奖",
    "edcor_award_short": "爱德科",
    "global_health_impact_network": "全球健康影响网络",
    "global_health_impact_network_short": "欣",
    "employer_partner": "雇主伙伴奖学金",
    "employer_partner_short": "雇主伙伴",
    "recruitment": "招聘奖学金",
    "recruitment_short": "招聘",
    "strada": "斯特拉达领导奖学金",
    "strada_short": "斯特拉达领导层",
    "impact_quantic": "量化影响奖学金",
    "impact_quantic_short": "量化影响",
    "powell": "鲍威尔援助奖学金",
    "powell_short": "鲍威尔援助",
    "impact_valar": "Valar 影响奖学金",
    "impact_valar_short": "维拉冲击",
    "hyperion": "Hyperion 援助奖学金",
    "hyperion_short": "Hyperion 援助",
    "base_scholarship": "奖学金",
    "strada_application": "领袖奖学金",
    "impact_quantic_application": "影响奖学金",
    "powell_application": "助学金",
    "impact_valar_application": "影响奖学金",
    "employer_partner_application": "雇主伙伴奖学金",
    "recruitment_application": "招聘奖学金",
    "hyperion_application": "助学金",
    "strada_female": "斯特拉达女性领导力奖学金",
    "strada_female_short": "斯特拉达女性领导力",
    "exec_ed_launch": "执行教育启动奖学金",
    "exec_ed_launch_short": "执行教育启动奖学金",
    "alumni": "校友奖学金",
    "alumni_application": "校友奖学金",
    "zenith": "领导力奖学金",
    "zenith_short": "领导",
    "zenith_application": "领导力奖学金",
    "impact_global": "全球影响力奖学金",
    "alumni_quantic_launch": "Quantic 启动奖学金"
}
};