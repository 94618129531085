window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.search_engine_optimization = window.locales.am.search_engine_optimization || {};window.locales.am.search_engine_optimization.site_metadata_mixin = window.locales.am.search_engine_optimization.site_metadata_mixin || {};window.locales.am.search_engine_optimization.site_metadata_mixin = {...window.locales.am.search_engine_optimization.site_metadata_mixin, ...{
    "default_share_title": "{{brandName}} - የ {{brandName}} MBA ያግኙ። የሚያልሙትን ስራ ላይ ያግኙ።",           
    "default_share_description": "የ Downton Abbey 3 ምእራፎችን በቢንግ ከተመለከቱበት ጊዜ ከድርጅት ተንጠልጣይነት እስከ ሲኒየር ዳይሬክተርነት ድረስ ይሂዱ። በቀላል ቅርጸት የቢዝነስ ብልህነቶችን በነጻ ይማሩ!",           
    "default_share_tweet_template": "ከ {{twitterName}} #LearningByDoing #Freeducation ጋር በቀን ውስጥ የ 5 ደቂቃ ገበያ ላይ የሚውሉ የቢዝነስ ክህሎቶች",           
    "default_share_tweet_template_quantic": "የወደፊቱ የቢዝነስ ት/ቤት እዚህ ነው! በ {{twitterName}} የዲግሪ ፕሮግራሞች ውስጥ አለም አቀፍ ቴክኖሎጂ እና የቢዝነስ መሪዎች ጋር ይቀላቀሉ። #ModernMBA",           
    "default_share_tweet_template_miya_miya": "ከ {{twitterName}} ትናንሽ የሂሳብ ትምህርቶችን መማር! #FreeEducation",           
    "default_share_email_title": "ይህንን ይመልከቱ",           
    "default_share_email_title_miya_miya": "ከ Miya Miya ጋር ይማሩ",           
    "default_share_email_description": "አስፈላጊ የቢዝነስ ክህሎቶችን በፍጥነት እንዲካኗቸው እና የሚያልሙት ስራ ላይ ለመድረስ፣ {{brandName}}ን ሊወዷቸው ይችላሉ ብዬ አስቤያለሁ። ። \n\nለመጀመር እዚህ ጋር ጠቅ ያድርጉ፡ {{canonicalUrl}}\n\n{{signature}}",           
    "default_share_course_email_description": "ሃይ፣ \n\nአስፈላጊ የቢዝነስ ክህሎቶችን በፍጥነት እንዲካኗቸው እና የሚያልሙት ስራ ላይ ለመድረስ፣{{brandName}} ን ሊወዷቸው ይችላሉ ብዬ አስቤያለሁ።\n\n{{courseTitle}}ን እንዲሞክሩት እጠቁምዎታለሁ። {{courseDescription}}\n\nለመጀመር እዚህ ጋር ጠቅ ያድርጉ፡ {{canonicalUrl}}\n\n{{signature}}",           
    "default_share_course_email_description_miya_miya": "ሃይ፣ \n\nየሂሳብ ክህሎቶችዎን እንዲካኑ የሚረዳዎ እና የሚቀጥለውን የትምህርት ቤት ፈተና ላይ ዌታማ እንዲሆኑ የሚያደርገውን አዲስ መድረክ የሆነው Miya Miyaን ሊወዱት ይችላሉ ብዬ አስቤያለሁ።\n\n {{courseTitle}}ን እንዲሞክሩት እጠቁምዎታለሁ። {{courseDescription}}\n\nለመጀመር እዚህ ጋር ጠቅ ያድርጉ፡ {{canonicalUrl}}\n\n{{signature}}",           
    "smartly_demo_twitter": "ፕሮባቢሊቲን በ {{twitterName}} 4 ደቂቃዎች ውስጥ ተማርኩ! #ProbabilityPro",           
    "blue_ocean_share_title": "Blue Ocean ስትራቴጂን ይማሩ - ይመዝገቡ",           
    "blue_ocean_share_description": "ፈጠራን የሚሸልመው እና ጎን ለጎን ውድድርን ለሚያዘጋጀው ታዋቂ የቢዝነስ ስትራቴጂ ለማግኘት ይመዝገቡ። ከ {{brandName}} ጋር የ Blue Ocean ስትራቴጂን እንዴት እንደሚተገብሩ ይወቁ!",           
    "blue_ocean_share_tweet_template": "Master #BlueOcean ስትራቴጂ ከአዲሱ ኮር ጋር በ {{socialHashtag}}!  {{url}} በ {{twitterName}} @BlueOceanStrtgy @HarvardBiz በኩል",           
    "blue_ocean_share_email_title": "ይህንን ይመልከቱ",           
    "blue_ocean_share_email_description": "ሃይ፣ ያለፈው አስር አመት ቁጥር 1 የተሸጠ የቢዝነስ መጽሃፍ የሆነውን በ Blue Ocean ስትራቴጂ ላይ ያለው የ \n\n {{brandName}} አነስተኛ ኮርስን ለመውሰድ ሊወዱት ይችላሉ ብዬ አስቤያለው።\n\nበዚህ ኮርስ ውስጥ የእርስዎን የቢዝነስ ውድድር አይግቡብነት የሌለው ለማድረግ፣ ውድድር የሌላው የገበያ ቦታን እንዴት እንደሚፈጥሩ ይማራሉ። የ Blue Ocean ስትራቴጂ \n\nሊንክ ይኅው ፤{{brandName}}{{canonicalUrl}}\n\n{{signature}}"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.search_engine_optimization = window.locales.ar.search_engine_optimization || {};window.locales.ar.search_engine_optimization.site_metadata_mixin = window.locales.ar.search_engine_optimization.site_metadata_mixin || {};window.locales.ar.search_engine_optimization.site_metadata_mixin = {...window.locales.ar.search_engine_optimization.site_metadata_mixin, ...{
    "default_share_description": "احصل على ترقية من موظف بسيط في الشركة إلى مدير كبير بها في الوقت الذي تضيعه في مشاهدة 3 مواسم من مسلسل داونتون آبي. تعلم إدارة الأعمال مجاناً بشكل ذكي وبسيط كتناول وجبة خفيفة!",           
    "default_share_tweet_template": "مهارات الأعمال الأكثر رواجاً في 5 دقائق يومياً مع {{twitterName}} #LearningByDoing #Freeducation",           
    "default_share_email_title": "تحقق من هذا",           
    "smartly_demo_twitter": "لقد تعلمت الاحتمالات مع {{twitterName}} في 4 دقائق! #ProbabilityPro",           
    "blue_ocean_share_title": "تعلم استراتيجية المحيط الأزرق - التسجيل",
    "blue_ocean_share_tweet_template": "ماجستير #BlueOcean استراتيجية مع دورة جديدة من قبل {{socialHashtag}}! {{url}} via {{twitterName}} @BlueOceanStrtgy @HarvardBiz",
    "blue_ocean_share_email_title": "تحقق من هذا",
    "default_share_title": "{{brandName}} - احصل على {{brandName}} ماجستير في إدارة الأعمال. ارض احلامك",
    "default_share_email_description": "اعتقدت أنك قد تستمتع {{brandName}} ، منصة جديدة لمساعدتك بسرعة على إتقان مهارات العمل الأساسية {{brandName}} إلى وظيفة أحلامك. . انقر هنا للبدء: {{canonicalUrl}} {{signature}}",
    "default_share_course_email_description": "مرحبًا ، أعتقد أنك قد تستمتع {{brandName}} ، منصة جديدة لمساعدتك على إتقان مهارات العمل الأساسية {{brandName}} إلى وظيفة أحلامك. أقترح عليك تجربة {{courseTitle}} . {{courseDescription}} انقر هنا للبدء: {{canonicalUrl}} {{signature}}",
    "blue_ocean_share_description": "قم بالتسجيل لاكتشاف استراتيجية أعمال جريئة تكافئ الابتكار وتضع المنافسة جانباً. تعرّف على كيفية تنفيذ استراتيجية المحيط الأزرق باستخدام {{brandName}} !",
    "blue_ocean_share_email_description": "مرحبًا ، أعتقد أنك قد ترغب في الحصول على {{brandName}} المصغرة حول Blue Ocean Strategy ، وهو كتاب الأعمال الأول للبيع في العقد الماضي. في هذه الدورة ، ستتعرف على كيفية إنشاء مساحة سوق بلا منازع لجعل منافسة عملك غير ذات صلة. إليك الرابط الخاص {{brandName}} المحيط الأزرق {{brandName}} : {{canonicalUrl}} {{signature}}",
    "default_share_tweet_template_quantic": "كلية إدارة الأعمال في المستقبل هنا! انضم إلى {{twitterName}} التكنولوجيا والأعمال {{twitterName}} برامج شهادات {{twitterName}} . #ModernMBA",
    "default_share_tweet_template_miya_miya": "تعلّم أن تحب الرياضيات مع دروس قصيرة متوفرة من قبل {{twitterName}}\n#FreeEducation #تعليم-مجاني",           
    "default_share_course_email_description_miya_miya": "مرحبا،\n\nإنني أشارك معك تطبيقاً جديداً أتوقع أن ينال إعجابك، مية مية يساعدك على إتقان مهارات الرياضيات & وإحراز علامات مرتفعة في امتحانات المدرسة.\n\nاقترح عليك أنّ تجرب {{courseTitle}}. {{courseDescription}}\n\nقم بالضغط هنا لتبدأ: {{canonicalUrl}}\n\n{{signature}}",           
    "default_share_email_title_miya_miya": "تعلّم مع تطبيق مية مية"           
}
};window.locales.en = window.locales.en || {};window.locales.en.search_engine_optimization = window.locales.en.search_engine_optimization || {};window.locales.en.search_engine_optimization.site_metadata_mixin = window.locales.en.search_engine_optimization.site_metadata_mixin || {};window.locales.en.search_engine_optimization.site_metadata_mixin = {...window.locales.en.search_engine_optimization.site_metadata_mixin, ...{
    "default_share_title": "{{brandName}} - Earn a {{brandName}} MBA. Land Your Dream Job.",
    "default_share_description": "Go from corporate scrub to senior director in the time you binge-watched 3 seasons of Downton Abbey. Learn business smarts in a snackable format for free!",
    "default_share_tweet_template": "Marketable business skills in 5 minutes a day with {{twitterName}} #LearningByDoing #Freeducation",
    "default_share_tweet_template_quantic": "The business school of the future is here! Join global tech and business leaders in the {{twitterName}} degree programs. #ModernMBA",
    "default_share_tweet_template_miya_miya": "Learning to love math with bite-sized lessons from {{twitterName}}! #FreeEducation",
    "default_share_email_title": "Check this out",
    "default_share_email_title_miya_miya": "Learn with Miya Miya",
    "default_share_email_description": "I thought you might enjoy {{brandName}}, a new platform to help you quickly master essential business skills & land your dream job. . \n\nClick here to get started: {{canonicalUrl}}\n\n{{signature}}",
    "default_share_course_email_description": "Hi,\n\nI thought you might enjoy {{brandName}}, a new platform to help you master essential business skills & land your dream job.  \n\nI suggest you try {{courseTitle}}. {{courseDescription}}\n\nClick here to get started: {{canonicalUrl}}\n\n{{signature}}",
    "default_share_course_email_description_miya_miya": "Hi,\n\nI thought you might enjoy Miya Miya, a new platform to help you master math skills & ace your next school exam.\n\nI suggest you try {{courseTitle}}. {{courseDescription}}\n\nClick here to get started: {{canonicalUrl}}\n\n{{signature}}",
    "smartly_demo_twitter": "I learned Probability with {{twitterName}} in 4 minutes! #ProbabilityPro",
    "blue_ocean_share_title": "Learn Blue Ocean Strategy - Sign up",
    "blue_ocean_share_description": "Sign up to discover a bold business strategy that rewards innovation and casts competition aside. Learn how to implement a Blue Ocean Strategy with {{brandName}}!",
    "blue_ocean_share_tweet_template": "Master #BlueOcean Strategy with the new course by {{socialHashtag}}!  {{url}} via {{twitterName}} @BlueOceanStrtgy @HarvardBiz",
    "blue_ocean_share_email_title": "Check this out",
    "blue_ocean_share_email_description": "Hi,\n\nI thought you might like to take {{brandName}}’s mini course on Blue Ocean Strategy, the #1 selling business book of the last decade. \n\nIn this course you’ll learn how to create uncontested market space to make your business's competition irrelevant.\n\nHere's the link to {{brandName}}'s Blue Ocean Strategy: {{canonicalUrl}}\n\n{{signature}}"
}
};window.locales.es = window.locales.es || {};window.locales.es.search_engine_optimization = window.locales.es.search_engine_optimization || {};window.locales.es.search_engine_optimization.site_metadata_mixin = window.locales.es.search_engine_optimization.site_metadata_mixin || {};window.locales.es.search_engine_optimization.site_metadata_mixin = {...window.locales.es.search_engine_optimization.site_metadata_mixin, ...{
    "default_share_description": "Pasa de miembro corporativo a director sénior en el tiempo en que ves tres temporadas de Downtown Abbey. ¡Aprende la inteligencia de los negocios gratis en un formato ameno!",           
    "default_share_tweet_template": "Habilidades comerciales negociables en cinco minutos al día con {{twitterName}} #LearningByDoing #Freeducation",           
    "default_share_email_title": "Echa un vistazo a esto",           
    "smartly_demo_twitter": "¡Aprendí probabilidad con {{twitterName}} en 4 minutos! #ProbabilityPro",           
    "blue_ocean_share_title": "Aprender Blue Ocean Strategy - Registrarse",
    "blue_ocean_share_tweet_template": "Master #BlueOcean Strategy con el nuevo curso de {{socialHashtag}}! {{url}} a través de {{twitterName}} @BlueOceanStrtgy @HarvardBiz",
    "blue_ocean_share_email_title": "Mira esto",
    "default_share_title": "{{brandName}} - Gana un {{brandName}} MBA. Consigue el trabajo de tus sueños.",
    "default_share_email_description": "Pensé que podría disfrutar {{brandName}} , una nueva plataforma para ayudarlo a dominar rápidamente las habilidades comerciales esenciales y conseguir el trabajo de sus sueños. . Haga clic aquí para comenzar: {{canonicalUrl}} {{signature}}",
    "default_share_course_email_description": "Hola, pensé que podrías disfrutar de {{brandName}} , una nueva plataforma para ayudarte a dominar las habilidades comerciales esenciales y conseguir el trabajo de tus sueños. Te sugiero que pruebes {{courseTitle}} . {{courseDescription}} Haga clic aquí para comenzar: {{canonicalUrl}} {{signature}}",
    "blue_ocean_share_description": "Regístrese para descubrir una estrategia empresarial audaz que premia la innovación y deja de lado la competencia. ¡Aprenda cómo implementar una estrategia de Blue Ocean con {{brandName}} !",
    "blue_ocean_share_email_description": "Hola, pensé que te gustaría tomar el mini curso de {{brandName}} sobre Blue Ocean Strategy, el libro comercial número 1 en ventas de la última década. En este curso aprenderá cómo crear un espacio de mercado incontestado para que la competencia de su empresa sea irrelevante. Aquí está el enlace a la estrategia de Blue Ocean de {{brandName}} : {{canonicalUrl}} {{signature}}",
    "default_share_tweet_template_quantic": "¡La escuela de negocios del futuro está aquí! Únase a los líderes mundiales en tecnología y negocios en los programas de grado {{twitterName}} . #ModernMBA",
    "default_share_tweet_template_miya_miya": "¡Aprendiendo a amar las matemáticas con lecciones del tamaño de un bocado de {{twitterName}}! #Educación gratis",
    "default_share_course_email_description_miya_miya": "Hola, pensé que podrías disfrutar de Miya Miya, una nueva plataforma para ayudarte a dominar las habilidades matemáticas y obtener tu próximo examen escolar. Te sugiero que pruebes {{courseTitle}} . {{courseDescription}} Haga clic aquí para comenzar: {{canonicalUrl}} {{signature}}",
    "default_share_email_title_miya_miya": "Aprende con Miya Miya"
}
};window.locales.it = window.locales.it || {};window.locales.it.search_engine_optimization = window.locales.it.search_engine_optimization || {};window.locales.it.search_engine_optimization.site_metadata_mixin = window.locales.it.search_engine_optimization.site_metadata_mixin || {};window.locales.it.search_engine_optimization.site_metadata_mixin = {...window.locales.it.search_engine_optimization.site_metadata_mixin, ...{
    "default_share_description": "Passa da commesso aziendale a direttore senior nel tempo che impeghi a divorare una stagione di Downton Abbey. Apprendi nozioni di impresa gratuitamente e a piccole dosi!",           
    "default_share_tweet_template": "Competenze d'impresa spendibili ottenute con 5 minuti al giorno grazie a {{twitterName}} #LearningByDoing #Freeducation",           
    "default_share_email_title": "Dai un'occhiata qui",           
    "smartly_demo_twitter": "Ho imparato Probabilità con {{twitterName}} in 4 minuti! #ProbabilityPro",           
    "blue_ocean_share_title": "Scopri la strategia Blue Ocean - Iscriviti",
    "blue_ocean_share_tweet_template": "Master #BlueOcean Strategy con il nuovo corso di {{socialHashtag}}! {{url}} tramite {{twitterName}} @BlueOceanStrtgy @HarvardBiz",
    "blue_ocean_share_email_title": "Controllalo",
    "default_share_title": "{{brandName}} : guadagna un MBA {{brandName}} . Land Your Dream Job.",
    "default_share_email_description": "Ho pensato che ti sarebbe piaciuto {{brandName}} , una nuova piattaforma per aiutarti a padroneggiare rapidamente le competenze aziendali essenziali e ottenere il lavoro dei tuoi sogni. . Fai clic qui per iniziare: {{canonicalUrl}} {{signature}}",
    "default_share_course_email_description": "Ciao, ho pensato che ti sarebbe piaciuto {{brandName}} , una nuova piattaforma per aiutarti a padroneggiare le competenze aziendali essenziali e realizzare il lavoro dei tuoi sogni. Ti suggerisco di provare {{courseTitle}} . {{courseDescription}} Fai clic qui per iniziare: {{canonicalUrl}} {{signature}}",
    "blue_ocean_share_description": "Iscriviti per scoprire una strategia aziendale audace che premia l'innovazione e allontana la concorrenza. Scopri come implementare una strategia Blue Ocean con {{brandName}} !",
    "blue_ocean_share_email_description": "Ciao, ho pensato che ti sarebbe piaciuto seguire il mini corso di {{brandName}} su Blue Ocean Strategy, il libro di esercizi commerciali numero uno dell'ultimo decennio. In questo corso imparerai come creare uno spazio di mercato incontestato per rendere irrilevante la concorrenza della tua azienda. Ecco il link alla strategia Blue Ocean di {{brandName}} : {{canonicalUrl}} {{signature}}",
    "default_share_tweet_template_quantic": "La business school del futuro è qui! Unisciti ai leader tecnologici e commerciali globali nei programmi di laurea {{twitterName}} . #ModernMBA",
    "default_share_tweet_template_miya_miya": "Imparare ad amare la matematica con lezioni di dimensioni ridotte da {{twitterName}}! #FreeEducation",
    "default_share_course_email_description_miya_miya": "Ciao, ho pensato che ti potesse piacere Miya Miya, una nuova piattaforma per aiutarti a padroneggiare le abilità matematiche e completare il tuo prossimo esame scolastico. Ti suggerisco di provare {{courseTitle}} . {{courseDescription}} Fai clic qui per iniziare: {{canonicalUrl}} {{signature}}",
    "default_share_email_title_miya_miya": "Impara con Miya Miya"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.search_engine_optimization = window.locales.zh.search_engine_optimization || {};window.locales.zh.search_engine_optimization.site_metadata_mixin = window.locales.zh.search_engine_optimization.site_metadata_mixin || {};window.locales.zh.search_engine_optimization.site_metadata_mixin = {...window.locales.zh.search_engine_optimization.site_metadata_mixin, ...{
    "default_share_description": "在您狂看三季《唐顿庄园》的时间里，从公司小工晋升为高级主管。以快餐化的方式免费学习商业智慧！",           
    "default_share_tweet_template": "每天 5 分钟通过 {{twitterName}} #LearningByDoing #Freeducation 学习实用的业务技能",           
    "default_share_email_title": "试试看吧",           
    "smartly_demo_twitter": "我通过 {{twitterName}} 在 4 分钟内学会了“概率”！#ProbabilityPro",           
    "blue_ocean_share_title": "了解蓝海战略——注册",           
    "blue_ocean_share_tweet_template": "通过{{socialHashtag}}的新课程掌握#蓝海战略！  {{url}}通过{{twitterName}} @BlueOceanStrtgy @HarvardBiz",           
    "blue_ocean_share_email_title": "进行查看",           
    "default_share_title": "{{brandName}}——获得{{brandName}}的工商管理硕士。找到梦想的工作。",           
    "default_share_email_description": "我想你可能会喜欢{{brandName}}，这是一个帮助你快速掌握基本商业技能和找到理想工作的全新平台。. \r\n\r\n点击此处开始：{{canonicalUrl}}\r\n\r\n{{signature}}",           
    "default_share_course_email_description": "你好，\r\n\r\n我想你可能会喜欢{{brandName}}，这是一个帮助你快速掌握基本商业技能和找到理想工作的全新平台。  \r\n\r\n我建议你尝试{{courseTitle}}。{{courseDescription}}\r\n\r\n点击此处开始：{{canonicalUrl}}\r\n\r\n{{signature}}",           
    "blue_ocean_share_description": "进行注册以探索这项奖励创新、抛弃竞争的前卫商业战略。学习如何使用{{brandName}}实现蓝海战略！",           
    "blue_ocean_share_email_description": "你好，\r\n\r\n我想你可能会喜欢{{brandName}}关于蓝海战略的迷你课程，这是过去十年最畅销的商业书籍。 \r\n\r\n在本课程中，你可以学习如何创建无竞争的市场空间，让你的企业免于面临竞争。\r\n\r\n以下是{{brandName}}的蓝海战略链接：{{canonicalUrl}}\r\n\r\n{{signature}}",           
    "default_share_tweet_template_quantic": "未来的商学院就在这里！在{{twitterName}}学位课程中加入全球科技和商业领袖。#现代工商管理硕士",           
    "default_share_tweet_template_miya_miya": "从{{twitterName}}中学习通过这些小课程爱上数学！#免费教育",           
    "default_share_course_email_description_miya_miya": "你好，\r\n\r\n我想你可能会喜欢Miya Miya，这是一个帮助你掌握数学技能并在下次学校考试中取得好成绩的全新平台。\r\n\r\n我建议你尝试{{courseTitle}}。{{courseDescription}}\r\n\r\n点击此处开始：{{canonicalUrl}}\r\n\r\n{{signature}}",           
    "default_share_email_title_miya_miya": "与Miya Miya一起学习"           
}
};