window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.reports = window.locales.am.reports || {};window.locales.am.reports.reports = window.locales.am.reports.reports || {};window.locales.am.reports.reports = {...window.locales.am.reports.reports, ...{
    "activity": "የእንቅስቃሴ ሪፖርት",           
    "apply": "ያመልክቱ",           
    "clear": "ያጽዱ",           
    "completed": "ተጠናቋል።",           
    "completed_subtext": "ተጠናቋል",           
    "course": "ኮርስ",           
    "course_activity": "የኮርስ እንቅስቃሴ",           
    "date_range_zoom_daily": "እለታዊ",           
    "date_range_zoom_monthly": "ወርሃዊ",           
    "date_range_zoom_weekly": "ሳምንታዊ",           
    "date_range_interval_between": "መካከል",           
    "date_range_interval_last": "የመጨረሻ",           
    "date_range_interval_since": "ከ",           
    "date_range_label_show_all": "ሁሉንም አሳይ",           
    "date_range_label_between": "በ {{start}} እና {{end}} መካከል",           
    "date_range_label_last_day_plural": "የመጨረሻ {{value}} ቀናት",           
    "date_range_label_last_day_singular": "የመጨረሻ {{value}} ቀን",           
    "date_range_label_last_week_plural": "የመጨረሻ {{value}} ሳምንታት",           
    "date_range_label_last_week_singular": "የመጨረሻ {{value}} ሳምንት",           
    "date_range_label_last_month_plural": "የመጨረሻ {{value}} ወራት",           
    "date_range_label_last_month_singular": "የመጨረሻ {{value}} ወር",           
    "date_range_label_since": "ከ {{start}}",           
    "date_range_unit_days": "ቀናት",           
    "date_range_unit_months": "ወራት",           
    "date_range_unit_weeks": "ሳምንታት",           
    "export_as_csv": "እንደ CSV አድርገው ወደውጪ ይላኩ",           
    "file_name_activity_report": "users_activity",           
    "file_name_course_activity": "user_courses_activity",           
    "filter_empty_courses": "(ምንም ኮርሶች የሉም)",           
    "filter_empty_courses_completed": "(ምንም ኮርሶች አልተጠናቀቁም)",           
    "filter_empty_courses_started": "(ምንም ኮርሶች አልተጀመሩም)",           
    "filter_empty_groups": "(ምንም ቡድኖች የሉም)",           
    "filter_empty_institutions": "(ምንም ተቋም የለም)",           
    "filter_empty_roles": "(ምንም ድርሻ የለም)",           
    "filter_empty_sign_up_codes": "(ምንም የምዝገባ ኮድ የለም)",           
    "filter_empty_unknown": "(ምንም)",           
    "filter_group_by_group": "ቡድን",           
    "filter_group_by_institution": "ተቋም",           
    "filter_group_by_role": "ድርሻ",           
    "filter_group_by_sign_up_code": "የምዝገባ ኮድ",           
    "filter_label_cohorts": "Cohorts",           
    "filter_label_compare": "ያነጻጽሩ",           
    "filter_label_courses": "ኮርሶች",           
    "filter_label_courses_completed": "ኮርሶች ተጠናቅቀዋል",           
    "filter_label_courses_started": "ኮርሶች ተጀምረዋል",           
    "filter_label_username_or_name": "የተጠቃሚ ስም ወይም ስም",           
    "filter_label_groups": "ቡድኖች",           
    "filter_label_institutions": "ተቋማት",           
    "filter_label_roles": "ደርሻዎች",           
    "filter_label_sign_up_codes": "የምዝገባ ኮዶች",           
    "filter_placeholder_cohorts": "Cohorts (ሁሉም)",           
    "filter_placeholder_client_types": "ደንበኛ(ሁሉም)",           
    "filter_placeholder_compare": "ያነጻጽሩ (ምንም)",           
    "filter_placeholder_courses": "ኮርሶች (ሁሉም)",           
    "filter_placeholder_lessons": "ትምህርቶች (ሁሉም)",           
    "filter_placeholder_locale": "አካባቢያዊ (ሁሉም)",           
    "filter_placeholder_courses_completed": "ኮርሶች ተጠናቅቀዋል (ሁሉም)",           
    "filter_placeholder_courses_started": "ኮርሶች ተጀምረዋል (ሁሉም)",           
    "filter_placeholder_text": "የያዘው...",           
    "filter_placeholder_groups": "ቡድን (ሁሉም)",           
    "filter_placeholder_institutions": "ተቋም (ሁሉም)",           
    "filter_placeholder_roles": "ሚና (ሁሉም)",           
    "filter_placeholder_sign_up_codes": "የምዝገባ ኮድ (ሁሉም)",           
    "filter_placeholder_registered": "መተግበሪያው ተመዝግቧል?",           
    "in_progress": "ተመዝግቧል?",           
    "last_seen": "ለመጨረሻ ጊዜ የታየው",           
    "last_seen_x": "ለመጨረሻ ጊዜ የታየው {{when}}",           
    "maintenance_mode_message": "<h2>ሪፖርቶች በአሁኑ ጊዜ መደበኛ ጥገና እያደረጉ ነው። <br> ብዙም ሳይቆዩ እባክዎ እንደገና ይሞክሩ! <br> <button ng-click=\"reload()\" class=\"flat coral hollow apply\">አድስ</button></h2>",           
    "mark_as_not_started": "አልተጀመረም ብለው ምልክት ያድርጉ",           
    "reset_timer_time_left": "ጊዜ ቆጣሪውን ዳግም ያስጀምሩ ({{msLeft | amDurationFormat}} ግራ)",           
    "reset_timer_time_expired": "ጊዜ ቆጣሪውን ዳግም ያስጀምሩ (ምንም ጊዜ አይቀርም)",           
    "reset_timer_timer_not_started": "ጊዜ ቆጣሪው አልጀመረም",           
    "no_report_loaded": "<h2>ከላይ ያለውን ማጣሪያ ይምረጡ። <br> ውሂብን<button ng-click=\"reload()\" class=\"flat coral hollow apply\">ለመጫን</button> አድስ የሚለውን ጠቅ ያድርጉ።</h2>",           
    "no_results_found": "<h2>ምንም ውጤቶች አልተገኙም! <br> ማጣሪያዎችን ያስተካክሉ ወይም እንደገና ለመጀመር <button ng-click=\"clear()\" class=\"flat beigebeyonddark hollow clear\">አጽዳ</button> የሚለውን ጠቅ ያድርጉ።</h2>",           
    "no_results_found_short": "<h2>ምንም ውጤቶች አልተገኙም!</h2>",           
    "no_user_selected": "ምንም ተጠቃሚ አልተመረጠም",           
    "overview": "አጠቃላይ እይታ",           
    "pagination_limit": "(ገደብ፡ {{limit}})",           
    "pagination_results_found": "{{num}}ውጤቶች አልተገኙም",           
    "quick_search_placeholder": "ውጤቶችን ያጣሩ...",           
    "quick_search_reset": "ማጣሪያን ዳግም ያስጀምሩ",           
    "refresh": "አድስ",           
    "registered_x": "ተመዝግቧል {{date}}",           
    "report_title_placeholder": "ሪፖርት",           
    "report_title_active_users": "ንቁ ተጠቃሚዎች",           
    "report_title_users": "ተጠቃሚዎች",           
    "report_title_player_lesson_sessions": "የትምህርት ክፍለ ጊዜያት",           
    "report_title_editor_lesson_sessions": "የአርታዒ ክፍለ ጊዜያት",           
    "report_title_time_on_task": "በስራው ላይ የሚያልፍ ጊዜ",           
    "reports_title": "ሪፖርቶች",           
    "report_y_axis_time_on_task_min": "በስራው ላይ የሚያልፍ ጊዜ (ደቂቃ)",           
    "reset_course_confirm": "ይህ ለተማሪው እንዳልተጀመረ ሆኖ እንዲታይ ኮርሱን እንደገና ያስጀምራል። ይቀጥላሉ?",           
    "reset_timer_confirm": "ተጠቃሚው ዳግም ለመክፈት እንዲችል ይህ በፈተናው ላይ የሰአት ቆጣሪው ዳግም እንዲጀምር ያደርጋል። ይቀጥላሉ?",           
    "reset_at": "ዳግም ያስጀምሩ",           
    "show_not_started": "ያልተጀመሩ ኮርሶችን አሳይ",           
    "started": "ጀምሯል",           
    "th_email": "ኢሜይል",           
    "th_name": "ስም",           
    "th_registered": "ተመዝግቧል",           
    "th_account_id": "የተጠቃሚ ስም",           
    "th_client_type": "ደንበኛ",           
    "th_courses": "ኮርሶች",           
    "th_course": "ኮርስ",           
    "th_lesson": "ትምህርት",           
    "th_lessons": "ትምህርቶች",           
    "th_courses_started": "ኮርሶች ተጀምረዋል",           
    "th_courses_completed": "ኮርሶች ተጠናቅቀዋል",           
    "th_lessons_started": "ትምህርቶች ተጀምረዋል",           
    "th_lessons_completed": "ትምህርቶች ተጠናቅቀዋል",           
    "th_status": "ሁኔታ",           
    "th_started": "ጀምሯል",           
    "th_completed": "ተጠናቋል",           
    "th_total_time": "ጠቅላላ ጊዜ",           
    "th_assessment": "የመመዘኛ ነጥቦች (አማካይ)",           
    "th_assess_score": "የመመዘኛ ነጥብ",           
    "th_assessment_group": "መመዘኛ",           
    "th_assessment_first": "የመጀመሪያ",           
    "th_assessment_best": "ምርጥ",           
    "th_assessment_score_first": "የመጀመሪያ ነጥብ",           
    "th_assessment_score_best": "ምርጥ ነጥብ",           
    "th_assessment_attempts_total": "የማጠናቀቂያ ብዛቶች",           
    "th_last_seen": "ለመጨረሻ ጊዜ የታየው",           
    "th_started_in_this_session": "የመጀመሪያ ክፍለ ጊዜ",           
    "th_completed_in_this_session": "ተጠናቋል",           
    "th_save_count": "ተቀምጧል",           
    "total_time": "ጠቅላላ ጊዜ",           
    "to_use_user_report": "ይህን ሪፖርት ለመጠቀም፣ በ <strong>እንቅስቃሴ ሪፖርት</strong> ውስጥ ተጠቃሚዎችን ያግኙ፣ ከዚያም እዚህ ጋር የእነርሱን ዝርዝሮች ለማየት የተጠቃሚውን ኢሜይል ጠቅ ያድርጉ።",           
    "user": "የተጠቃሚ ዝርዝር",           
    "view_activity_report": "የእንቅስቃሴ ሪፖርት ይመልከቱ",           
    "x_available_courses": "{{num}} የሚገኙ ኮርሶች",           
    "x_completed": "{{num}} ተጠናቋል",           
    "x_in_progress": "{{num}} በሂደት ላይ",           
    "status_started": "ጀምሯል",           
    "status_not_started": "አልተጀመረም",           
    "status_completed": "ተጠናቋል",           
    "lock": "ቆልፍ",
    "unlock": "ክፈት",
    "lock_course_confirm": "ተጠቃሚው እንዳይከፍተው ይህ ኮርሱን ይቆልፋል። ይቀጥሉ?",
    "unlock_course_confirm": "ተጠቃሚው እንዲከፍተው ይህ ኮርሱን ይከፍታል። ይቀጥሉ?",
    "authenticate": "አረጋጋጭ",
    "authenticate_tooltip": "ለዚህ ፈተና የፕሮክተር ማረጋገጫን አስተዳደራዊ ሽረው",
    "manually_authenticated": "በእጅ የተረጋገጠ",
    "manually_authenticate_stream_confirm": "ይህ ተጠቃሚው ለዚህ ፈተና የባዮሲግ-መታወቂያ ማረጋገጫ እንዲያልፍ ያስችለዋል። የፎቶ መታወቂያቸውን ሲያረጋግጡ ይጠቀሙ። ይቀጥሉ?"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.reports = window.locales.ar.reports || {};window.locales.ar.reports.reports = window.locales.ar.reports.reports || {};window.locales.ar.reports.reports = {...window.locales.ar.reports.reports, ...{
    "activity": "تقرير النشاط",           
    "apply": "تطبيق",           
    "clear": "مسح",           
    "completed": "مكتملة",           
    "completed_subtext": "مكتملة",           
    "course": "الدورة",           
    "course_activity": "نشاط الدورة",           
    "date_range_zoom_daily": "يومي",           
    "date_range_zoom_monthly": "شهري",           
    "date_range_zoom_weekly": "أسبوعي",           
    "date_range_interval_between": "بين",           
    "date_range_interval_last": "آخر",           
    "date_range_interval_since": "منذ",           
    "date_range_label_show_all": "عرض الكل",           
    "date_range_label_between": "بين {{start}} و{{end}}",           
    "date_range_label_last_day_plural": "آخر {{value}} أيام",           
    "date_range_label_last_day_singular": "آخر {{value}} اليوم",           
    "date_range_label_last_week_plural": "آخر {{value}} أسابيع",           
    "date_range_label_last_week_singular": "آخر {{value}} أسبوع",           
    "date_range_label_last_month_plural": "أخر {{value}} أشهر",           
    "date_range_label_last_month_singular": "آخر {{value}} شهر",           
    "date_range_label_since": "منذ {{start}}",           
    "date_range_unit_days": "أيام",           
    "date_range_unit_months": "أشهر",           
    "date_range_unit_weeks": "أسابيع",           
    "export_as_csv": "CSV تحميل بصيغة",           
    "file_name_activity_report": "نشاط_المستخدمين",           
    "file_name_course_activity": "نشاط_دورات_المستخدم",           
    "filter_empty_courses": "(لا توجد دورات)",           
    "filter_empty_courses_completed": "(لا توجد دورات مكتملة)",           
    "filter_empty_courses_started": "(لا توجد دورات تم البدء فيها)",           
    "filter_empty_groups": "(لا توجد مجموعات)",           
    "filter_empty_institutions": "(لا توجد مؤسسة)",           
    "filter_empty_roles": "(لا يوجد دور)",           
    "filter_empty_sign_up_codes": "(لا يوجد كود اشترك)",           
    "filter_empty_unknown": "(لا شيء)",           
    "filter_group_by_group": "مجموعة",           
    "filter_group_by_institution": "مؤسسة",           
    "filter_group_by_role": "دور",           
    "filter_group_by_sign_up_code": "كود الاشترك",           
    "filter_label_cohorts": "المجموعات",           
    "filter_label_compare": "مقارنة",           
    "filter_label_courses": "الدورات",           
    "filter_label_courses_completed": "الدورات المكتملة",           
    "filter_label_courses_started": "الدورات التي تم البدء فيها",           
    "filter_label_username_or_name": "اسم المستخدم أو الاسم",           
    "filter_label_groups": "المجموعات",           
    "filter_label_institutions": "المؤسسات",           
    "filter_label_roles": "الأدوار",           
    "filter_label_sign_up_codes": "أكواد الاشترك",           
    "filter_placeholder_cohorts": "المجموعات (الكل)",           
    "filter_placeholder_client_types": "الزبون (الكل)",           
    "filter_placeholder_compare": "مقارنة (لا شيء)",           
    "filter_placeholder_courses": "الدورات (الكل)",           
    "filter_placeholder_lessons": "الدروس (الكل)",           
    "filter_placeholder_locale": "الموقع (الكل)",           
    "filter_placeholder_courses_completed": "الدورات المكتملة (الكل)",           
    "filter_placeholder_courses_started": "الدورات التي تم البدء فيها (الكل)",           
    "filter_placeholder_text": "تحتوي على...",           
    "filter_placeholder_groups": "المجموعة (الكل)",           
    "filter_placeholder_institutions": "المؤسسة (الكل)",           
    "filter_placeholder_roles": "الدور (الكل)",           
    "filter_placeholder_sign_up_codes": "كود الاشترك (الكل)",           
    "in_progress": "قيد التنفيذ",           
    "last_seen": "آخر مشاهدة",           
    "last_seen_x": "آخر مشاهدة في {{when}}",           
    "maintenance_mode_message": "<h2> تخضع التقارير حالياً لأعمال الصيانة الروتينية. <br> يرجى إعادة المحاولة مرةً أخرى قريباً! <br> <button ng-click=\"reload()\" class=\"flat coral hollow apply\">تحديث</button></h2>",           
    "mark_as_not_started": "وضع علامة لم يتم البدء فيها",           
    "no_report_loaded": "<h2>حدد خيارات التصفية أعلاه. <br> انقر<button ng-click=\"reload()\" class=\"flat coral hollow apply\">تحديث</button> لتحديث البيانات.</h2>",           
    "no_results_found": "<h2>لم يتم العثور على أية نتائج! <br> قُم بضبط التصفيات أو انقر <button ng-click=\"clear()\" class=\"flat beigebeyonddark hollow clear\">مسح</button> للبدء من جديد.</h2>",           
    "no_results_found_short": "<h2>لم يتم العثور على أية نتائج!</h2>",           
    "no_user_selected": "لم يتم تحديد العضو",           
    "overview": "نظرة عامة",           
    "pagination_results_found": "تم العثور على {{num}} نتائج",           
    "quick_search_placeholder": "نتيجة التصفية...",           
    "quick_search_reset": "إعادة تعيين التصفية",           
    "refresh": "تحديث",           
    "registered_x": "تم التسجيل في {{date}}",           
    "report_title_placeholder": "التقرير",           
    "report_title_active_users": "الأعضاء النشطون",           
    "report_title_users": "المستخدمون",           
    "report_title_player_lesson_sessions": "جلسات الدرس",           
    "report_title_editor_lesson_sessions": "جلسات المحرر",           
    "report_title_time_on_task": "الوقت حسب المهمة",           
    "reports_title": "التقارير",           
    "report_y_axis_time_on_task_min": "الوقت حسب المهمة (دقيقة)",           
    "reset_course_confirm": "سوف يؤدي هذا إلى إعادة تعيين الدورة كي تظهر للدارس وكأنها لم يتم البدء فيها. هل تريد المتابعة؟",           
    "reset_at": "إعادة التعيين",           
    "show_not_started": "عرض الدورات التي لم يتم البدء فيها",           
    "started": "تم البدء فيها",           
    "th_email": "البريد الإلكتروني",           
    "th_name": "الاسم",           
    "th_registered": "تم التسجيل",           
    "th_account_id": "اسم المستخدم",           
    "th_client_type": "الزبون",           
    "th_courses": "الدورات",           
    "th_course": "الدورة",           
    "th_lesson": "الدرس",           
    "th_lessons": "الدروس",           
    "th_courses_started": "الدورات التي تم البدء فيها",           
    "th_courses_completed": "الدورات المكتملة",           
    "th_lessons_started": "الدروس التي تم البدء فيها",           
    "th_lessons_completed": "الدروس المكتملة",           
    "th_status": "الحالة",           
    "th_started": "تم البدء فيها",           
    "th_completed": "مكتملة",           
    "th_total_time": "الوقت الكلي",           
    "th_assessment": "نتائج التقييم (المتوسط)",           
    "th_assess_score": "نتيجة التقييم",           
    "th_assessment_group": "التقييم",           
    "th_assessment_first": "الأول",           
    "th_assessment_score_first": "النتيجة الأولى",           
    "th_assessment_attempts_total": "# مرات الإكمال",           
    "th_last_seen": "آخر ظهور",           
    "th_started_in_this_session": "الدورة الأولى",           
    "th_completed_in_this_session": "مكتملة",           
    "th_save_count": "يحفظ",           
    "total_time": "الوقت الكلي",           
    "to_use_user_report": "لاستخدام هذا التقرير، ابحث عن المستخدمين في <strong>تقرير النشاط</strong>، ثم انقر فوق البريد الإلكتروني للمستخدم لعرض تفاصيله هنا.",           
    "user": "معلومات المستخدم",           
    "view_activity_report": "عرض تقرير النشاط",           
    "x_available_courses": "{{num}} الدورات المتاحة",           
    "x_completed": "{{num}} مكتملة",           
    "x_in_progress": "{{num}} قيد التنفيذ",           
    "status_started": "تم البدء فيها",           
    "status_not_started": "لم يتم البدء فيها",           
    "status_completed": "مكتملة",           
    "pagination_limit": "(الحد: {{limit}} )",
    "th_assessment_best": "الأفضل",
    "th_assessment_score_best": "أفضل نتيجة",
    "reset_timer_time_left": "ريسيت {{msLeft | amDurationFormat}} ( {{msLeft | amDurationFormat}} لليسار)",
    "reset_timer_time_expired": "ريسيت تيمر (لا يوجد وقت متبقي)",
    "reset_timer_timer_not_started": "تيمر لم تبدأ",
    "reset_timer_confirm": "سيؤدي هذا إلى إعادة تعيين الموقت على الامتحان بحيث يمكن للمستخدم فتحه مرة أخرى. تقدم؟",
    "filter_placeholder_registered": "مسجل؟",
    "lock": "قفل",
    "unlock": "الغاء القفل",
    "lock_course_confirm": "سيؤدي هذا إلى قفل الدورة التدريبية حتى لا يتمكن المستخدم من فتحها. يتابع؟",
    "unlock_course_confirm": "سيؤدي هذا إلى فتح الدورة التدريبية حتى يتمكن المستخدم من فتحها. يتابع؟",
    "authenticate": "موثق",
    "authenticate_tooltip": "تجاوز التحقق الإداري لهذا الاختبار",
    "manually_authenticated": "معتمد يدويًا",
    "manually_authenticate_stream_confirm": "سيسمح هذا للمستخدم بتجاوز التحقق من معرف BioSig لهذا الاختبار. استخدمه عند التحقق من هويتهم المصورة. يتابع؟"
}
};window.locales.en = window.locales.en || {};window.locales.en.reports = window.locales.en.reports || {};window.locales.en.reports.reports = window.locales.en.reports.reports || {};window.locales.en.reports.reports = {...window.locales.en.reports.reports, ...{
    "activity": "Activity Report",
    "apply": "Apply",
    "clear": "Clear",
    "completed": "COMPLETED",
    "completed_subtext": "Completed",
    "course": "Course",
    "course_activity": "COURSE ACTIVITY",
    "date_range_zoom_daily": "Daily",
    "date_range_zoom_monthly": "Monthly",
    "date_range_zoom_weekly": "Weekly",
    "date_range_interval_between": "Between",
    "date_range_interval_last": "Last",
    "date_range_interval_since": "Since",
    "date_range_label_show_all": "Show all",
    "date_range_label_between": "Between {{start}} and {{end}}",
    "date_range_label_last_day_plural": "Last {{value}} days",
    "date_range_label_last_day_singular": "Last {{value}} day",
    "date_range_label_last_week_plural": "Last {{value}} weeks",
    "date_range_label_last_week_singular": "Last {{value}} week",
    "date_range_label_last_month_plural": "Last {{value}} months",
    "date_range_label_last_month_singular": "Last {{value}} month",
    "date_range_label_since": "Since {{start}}",
    "date_range_unit_days": "days",
    "date_range_unit_months": "months",
    "date_range_unit_weeks": "weeks",
    "export_as_csv": "Export as CSV",
    "file_name_activity_report": "users_activity",
    "file_name_course_activity": "user_courses_activity",
    "filter_empty_courses": "(No Courses)",
    "filter_empty_courses_completed": "(No Courses Completed)",
    "filter_empty_courses_started": "(No Courses Started)",
    "filter_empty_groups": "(No Groups)",
    "filter_empty_institutions": "(No Institution)",
    "filter_empty_roles": "(No Role)",
    "filter_empty_sign_up_codes": "(No Sign Up Code)",
    "filter_empty_unknown": "(None)",
    "filter_group_by_group": "Group",
    "filter_group_by_institution": "Institution",
    "filter_group_by_role": "Role",
    "filter_group_by_sign_up_code": "Sign Up Code",
    "filter_label_cohorts": "Cohorts",
    "filter_label_compare": "Compare",
    "filter_label_courses": "Courses",
    "filter_label_courses_completed": "Courses Completed",
    "filter_label_courses_started": "Courses Started",
    "filter_label_username_or_name": "Username or name",
    "filter_label_groups": "Groups",
    "filter_label_institutions": "Institutions",
    "filter_label_roles": "Roles",
    "filter_label_sign_up_codes": "Sign Up Codes",
    "filter_placeholder_cohorts": "Cohorts (all)",
    "filter_placeholder_client_types": "Client (all)",
    "filter_placeholder_compare": "Compare (none)",
    "filter_placeholder_courses": "Courses (all)",
    "filter_placeholder_lessons": "Lessons (all)",
    "filter_placeholder_locale": "Locale (all)",
    "filter_placeholder_courses_completed": "Courses Completed (all)",
    "filter_placeholder_courses_started": "Courses Started (all)",
    "filter_placeholder_text": "contains...",
    "filter_placeholder_groups": "Group (all)",
    "filter_placeholder_institutions": "Institution (all)",
    "filter_placeholder_roles": "Role (all)",
    "filter_placeholder_sign_up_codes": "Sign Up Code (all)",
    "filter_placeholder_registered": "Registered?",
    "in_progress": "In Progress",
    "last_seen": "Last seen",
    "last_seen_x": "Last seen {{when}}",
    "maintenance_mode_message": "<h2>Reports are currently undergoing routine maintenance. <br> Please try again shortly! <br> <button ng-click=\"reload()\" class=\"flat coral hollow apply\">Refresh</button></h2>",
    "mark_as_not_started": "MARK AS NOT STARTED",
    "lock": "LOCK",
    "unlock": "UNLOCK",
    "lock_course_confirm": "This will lock the course so the user cannot open it. Proceed?",
    "unlock_course_confirm": "This will unlock the course so the user can open it. Proceed?",
    "reset_timer_time_left": "RESET TIMER ({{msLeft | amDurationFormat}} left)",
    "reset_timer_time_expired": "RESET TIMER (No time left)",
    "reset_timer_timer_not_started": "TIMER NOT STARTED",
    "no_report_loaded": "<h2>Select filter options above. <br> Click <button ng-click=\"reload()\" class=\"flat coral hollow apply\">Refresh</button> to load data.</h2>",
    "no_results_found": "<h2>No results found! <br> Adjust filters or click <button ng-click=\"clear()\" class=\"flat beigebeyonddark hollow clear\">Clear</button> to start over.</h2>",
    "no_results_found_short": "<h2>No results found!</h2>",
    "no_user_selected": "No User Selected",
    "overview": "Overview",
    "pagination_limit": "(limit: {{limit}})",
    "pagination_results_found": "{{num}} results found",
    "quick_search_placeholder": "Filter results...",
    "quick_search_reset": "Reset Filter",
    "refresh": "Refresh",
    "registered_x": "Registered {{date}}",
    "report_title_placeholder": "Report",
    "report_title_active_users": "Active Users",
    "report_title_users": "Users",
    "report_title_player_lesson_sessions": "Lesson Sessions",
    "report_title_editor_lesson_sessions": "Editor Sessions",
    "report_title_time_on_task": "Time on Task",
    "reports_title": "REPORTS",
    "report_y_axis_time_on_task_min": "Time on Task (min)",                                                                    
    "reset_course_confirm": "This will reset the course to appear as not started to the learner. Proceed?",
    "reset_timer_confirm": "This will reset the timer on the exam so the user can open it again. Proceed?",
    "reset_at": "Reset",
    "show_not_started": "SHOW NOT STARTED COURSES",
    "started": "Started",
    "th_email": "Email",
    "th_name": "Name",
    "th_registered": "Registered",
    "th_account_id": "Username",
    "th_client_type": "Client",
    "th_courses": "Courses",
    "th_course": "Course",
    "th_lesson": "Lesson",
    "th_lessons": "Lessons",
    "th_courses_started": "Courses Started",
    "th_courses_completed": "Courses Completed",
    "th_lessons_started": "Lessons Started",
    "th_lessons_completed": "Lessons Completed",
    "th_status": "Status",
    "th_started": "Started",
    "th_completed": "Completed",
    "th_total_time": "Total Time",
    "th_assessment": "Assessment Scores (Avg)",
    "th_assess_score": "Assessment Score",
    "th_assessment_group": "Assessment",
    "th_assessment_first": "First",
    "th_assessment_best": "Best",
    "th_assessment_score_first": "First Score",
    "th_assessment_score_best": "Best Score",
    "th_assessment_attempts_total": "# Completions",
    "th_last_seen": "Last Seen",
    "th_started_in_this_session": "First Session",
    "th_completed_in_this_session": "Completed",
    "th_save_count": "Saves",
    "total_time": "Total Time",
    "to_use_user_report": "To use this report, search for users in the <strong>Activity Report</strong>, then click a user's email to view their details here.",
    "user": "User Detail",
    "view_activity_report": "VIEW ACTIVITY REPORT",
    "x_available_courses": "{{num}} available courses",
    "x_completed": "{{num}} completed",
    "x_in_progress": "{{num}} in progress",
    "status_started": "Started",
    "status_not_started": "Not Started",
    "status_completed": "Completed",
    "authenticate": "AUTHENTICATE",
    "authenticate_tooltip": "Administratively override proctoring verification for this exam",
    "manually_authenticated": "MANUALLY AUTHENTICATED",
    "manually_authenticate_stream_confirm": "This will allow the user to bypass BioSig-ID verification for this exam. Use when you have verified their photo ID. Proceed?"
}
};window.locales.es = window.locales.es || {};window.locales.es.reports = window.locales.es.reports || {};window.locales.es.reports.reports = window.locales.es.reports.reports || {};window.locales.es.reports.reports = {...window.locales.es.reports.reports, ...{
    "activity": "Informe de la actividad",           
    "apply": "Aplicar",           
    "clear": "Eliminar",           
    "completed_subtext": "Completado",           
    "date_range_zoom_daily": "Diariamente",           
    "date_range_zoom_monthly": "Mensualmente",           
    "date_range_zoom_weekly": "Semanalmente",           
    "date_range_interval_between": "Entre",           
    "date_range_interval_last": "Últimos",           
    "date_range_interval_since": "Desde",           
    "date_range_label_between": "Entre {{start}} y {{end}}",           
    "date_range_label_last_day_plural": "Últimos {{value}} días",           
    "date_range_label_last_day_singular": "Último {{value}} día",           
    "date_range_label_last_week_plural": "Últimas {{value}} semanas",           
    "date_range_label_last_week_singular": "Última {{value}} semana",           
    "date_range_label_last_month_plural": "Últimos {{value}} meses",           
    "date_range_label_last_month_singular": "Último {{value}} mes",           
    "date_range_label_since": "Desde el {{start}}",           
    "date_range_unit_days": "días",           
    "date_range_unit_months": "meses",           
    "date_range_unit_weeks": "semanas",           
    "filter_empty_groups": "(Ningún grupo)",           
    "filter_empty_institutions": "(Ninguna institución)",           
    "filter_empty_roles": "(Ninguna función)",           
    "filter_empty_sign_up_codes": "(Ningún código de registro)",           
    "filter_empty_unknown": "(Ninguno)",           
    "filter_group_by_group": "Grupo",           
    "filter_group_by_institution": "Institución",           
    "filter_group_by_role": "Función",           
    "filter_group_by_sign_up_code": "Código de registro",           
    "filter_label_compare": "Comparar",           
    "filter_label_groups": "Grupos",           
    "filter_label_institutions": "Instituciones",           
    "filter_label_roles": "Funciones",           
    "filter_label_sign_up_codes": "Códigos de registro",           
    "filter_label_cohorts": "Cohortes",           
    "filter_placeholder_compare": "Comparar (ninguno)",           
    "filter_placeholder_groups": "Grupo (todos)",           
    "filter_placeholder_institutions": "Institución (todas)",           
    "filter_placeholder_roles": "Función (todas)",           
    "filter_placeholder_sign_up_codes": "Código de registro (todos)",           
    "no_report_loaded": "<h2> Selecciona las opciones de filtro anteriores. Haz clic en <button ng-click=\"reload()\" class=\"flat coral hollow apply\">Refrescar</button> para cargar los datos.</h2>",           
    "no_results_found": "<H2> ¡No se han encontrado resultados! <br> Ajusta los filtros o haz clic en <button ng-click=\"clear()\" class=\"flat beigebeyonddark hollow clear\">Eliminar</button> para empezar de nuevo. </h2>",           
    "no_results_found_short": "<H2> ¡No se han encontrado resultados!</h2>",           
    "overview": "Resumen",           
    "refresh": "Refrescar",           
    "report_title_placeholder": "Informe",           
    "report_title_active_users": "Usuarios activos",           
    "reports_title": "INFORMES",           
    "user": "Detalle del usuario",           
    "completed": "TERMINADO",           
    "course_activity": "ACTIVIDAD DEL CURSO",           
    "export_as_csv": "Exportar como CSV",           
    "filter_empty_courses": "(No hay cursos)",           
    "filter_empty_courses_completed": "(No hay cursos completados)",           
    "filter_empty_courses_started": "(No hay cursos iniciados)",           
    "filter_label_courses": "Cursos",           
    "filter_label_courses_completed": "Cursos completados",           
    "filter_label_courses_started": "Cursos iniciados",           
    "filter_placeholder_courses": "Cursos (todos)",           
    "filter_placeholder_lessons": "Lecciones (todos)",           
    "filter_placeholder_courses_completed": "Cursos completados (todos)",           
    "filter_placeholder_courses_started": "Cursos iniciados (todos)",           
    "last_seen": "Visto por última vez ",           
    "last_seen_x": "Visto por última vez {{when}}",           
    "no_user_selected": "Ningún usuario seleccionado",           
    "pagination_results_found": "{{num}} resultados encontrados",           
    "quick_search_placeholder": "Filtrar resultados...",           
    "quick_search_reset": "Reiniciar filtro",           
    "registered_x": "Registrado {{date}}",           
    "report_title_users": "Usuarios",           
    "report_title_time_on_task": "Tiempo en el trabajo",           
    "report_y_axis_time_on_task_min": "Tiempo en el trabajo (min)",           
    "show_not_started": "MOSTRAR CURSOS NO INICIADOS",           
    "started": "Empezado",           
    "th_email": "Correo electrónico",           
    "th_name": "Nombre",           
    "th_registered": "Registrado",           
    "th_courses": "Cursos",           
    "th_lesson": "Lección",           
    "th_course": "Curso",           
    "th_lessons": "Lecciones",           
    "th_started": "Empezado",           
    "th_completed": "Terminado",           
    "th_total_time": "Tiempo total",           
    "th_assessment": "Resultados de la evaluación (med)",           
    "th_assess_score": "Resultados de la evaluación",           
    "th_last_seen": "Visto por última vez ",           
    "total_time": "Tiempo total",           
    "to_use_user_report": "Para utilizar este informe, busca usuarios en el <strong>Informe de actividad</strong> y, a continuación, haz clic en correo electrónico de un usuario para ver sus detalles aquí.",           
    "view_activity_report": "VER INFORME DE ACTIVIDAD",           
    "x_available_courses": "{{num}} cursos disponibles",           
    "x_completed": "{{num}} completados",           
    "x_in_progress": "{{num}} en curso",           
    "in_progress": "En curso",           
    "course": "Curso",           
    "file_name_activity_report": "actividad_usuarios",           
    "file_name_course_activity": "actividad_cursos_usuario",           
    "maintenance_mode_message": "<h2> Los informes se encuentran actualmente en estado de mantenimiento rutinario. <br> ¡Por favor, inténtalo de nuevo en breve! <br> <button ng-click=\"reload()\" class=\"flat coral hollow apply\">Refrescar</button></h2>",           
    "th_courses_started": "Cursos empezados",           
    "th_courses_completed": "Cursos completados",           
    "th_lessons_started": "Clases empezadas",           
    "th_lessons_completed": "Clases completadas",           
    "th_status": "Estado",           
    "status_started": "Empezado",           
    "status_not_started": "No empezado",           
    "status_completed": "Completado",           
    "filter_placeholder_cohorts": "Cursos en grupo (todos)",           
    "date_range_label_show_all": "Mostrar todo",           
    "report_title_player_lesson_sessions": "Sesiones de lecciones",           
    "th_started_in_this_session": "Primera sesión",           
    "th_completed_in_this_session": "Completada",           
    "filter_placeholder_locale": "Idioma (todos)",           
    "filter_empty_playlists_completed": "(No hay playlists terminadas)",           
    "filter_empty_playlists_started": "(No hay playlists empezadas)",           
    "filter_placeholder_playlists_started": "Playlists empezada (todas)",           
    "filter_placeholder_playlists_completed": "Playlists terminadas (todas)",           
    "report_title_editor_lesson_sessions": "Sesiones del editor",           
    "th_playlists": "Playlists",           
    "th_save_count": "Guardadas",           
    "th_assessment_group": "Evaluación",           
    "th_assessment_first": "Primer",           
    "th_assessment_best": "Mejor",
    "th_assessment_score_first": "Primer Puntaje",           
    "th_assessment_score_last": "Mejor Puntaje",
    "th_assessment_attempts_total": "# Terminados",           
    "filter_label_username_or_name": "Nombre de usuario o nombre",           
    "filter_placeholder_text": "contiene...",           
    "th_account_id": "Nombre de usuario",           
    "filter_placeholder_client_types": "Cliente (todos)",           
    "th_client_type": "Cliente",           
    "mark_as_not_started": "MARCAR COMO NO EMPEZADO",           
    "reset_course_confirm": "Esto restablecerá el curso para que aparezca como no iniciado para el alumno. ¿Proceder?",           
    "reset_at": "Reiniciar",           
    "pagination_limit": "(límite: {{limit}} )",
    "th_assessment_score_best": "Mejor puntuación",
    "reset_timer_time_left": "RESET TIMER ( {{msLeft | amDurationFormat}} izquierda)",
    "reset_timer_time_expired": "RESET TIMER (Sin tiempo restante)",
    "reset_timer_timer_not_started": "TIMER NO INICIADO",
    "reset_timer_confirm": "Esto restablecerá el temporizador en el examen para que el usuario pueda abrirlo de nuevo. ¿Proceder?",
    "filter_placeholder_registered": "¿Registrado?",
    "lock": "CERRAR",
    "unlock": "DESBLOQUEAR",
    "lock_course_confirm": "Esto bloqueará el curso para que el usuario no pueda abrirlo. ¿Proceder?",
    "unlock_course_confirm": "Esto desbloqueará el curso para que el usuario pueda abrirlo. ¿Proceder?",
    "authenticate": "AUTENTICAR",
    "authenticate_tooltip": "Anular administrativamente la verificación de supervisión para este examen",
    "manually_authenticated": "AUTENTICADO MANUALMENTE",
    "manually_authenticate_stream_confirm": "Esto permitirá al usuario omitir la verificación de BioSig-ID para este examen. Úselo cuando haya verificado su identificación con foto. ¿Proceder?"
}
};window.locales.it = window.locales.it || {};window.locales.it.reports = window.locales.it.reports || {};window.locales.it.reports.reports = window.locales.it.reports.reports || {};window.locales.it.reports.reports = {...window.locales.it.reports.reports, ...{
    "activity": "Riepilogo Attività",           
    "apply": "Candidati",           
    "clear": "Cancella",           
    "completed": "COMPLETATO",           
    "completed_subtext": "Completato",           
    "course": "Corso",           
    "course_activity": "ATTIVITÀ CORSO",           
    "date_range_zoom_daily": "Quotidiano",           
    "date_range_zoom_monthly": "Mensile",           
    "date_range_zoom_weekly": "Settimanale",           
    "date_range_interval_between": "Fra",           
    "date_range_interval_last": "Ultimo",           
    "date_range_interval_since": "Da",           
    "date_range_label_show_all": "Mostra tutto",           
    "date_range_label_between": "Fra {{start}} e {{end}}",           
    "date_range_label_last_day_plural": "Ultimi {{value}} giorni",           
    "date_range_label_last_day_singular": "Ultimo {{value}} giorno",           
    "date_range_label_last_week_plural": "Ultime {{value}} settimane",           
    "date_range_label_last_week_singular": "Ultima {{value}} settimana",           
    "date_range_label_last_month_plural": "Ultimi {{value}} mesi",           
    "date_range_label_last_month_singular": "Ultimo {{value}} mese",           
    "date_range_label_since": "Da {{start}}",           
    "date_range_unit_days": "giorni",           
    "date_range_unit_months": "mesi",           
    "date_range_unit_weeks": "settimane",           
    "export_as_csv": "Esporta come CSV",           
    "file_name_activity_report": "attività_utente",           
    "file_name_course_activity": "attività_corsi_utente",           
    "filter_empty_courses": "(Nessun Corso)",           
    "filter_empty_courses_completed": "(Nessun Corso Completato)",           
    "filter_empty_courses_started": "(Nessun Corso Iniziato)",           
    "filter_empty_groups": "(Nessun Gruppo)",           
    "filter_empty_institutions": "(Nessuna Istituzione)",           
    "filter_empty_roles": "(Nessuna Posizione)",           
    "filter_empty_sign_up_codes": "(Nessun Codice Registrazione)",           
    "filter_empty_unknown": "(Nessuno)",           
    "filter_group_by_group": "Gruppo",           
    "filter_group_by_institution": "Istituzione",           
    "filter_group_by_role": "Posizione",           
    "filter_group_by_sign_up_code": "Codice Registrazione",           
    "filter_label_cohorts": "Sessioni Accademiche",           
    "filter_label_compare": "Confronta",           
    "filter_label_courses": "Corsi",           
    "filter_label_courses_completed": "Corsi completati",           
    "filter_label_courses_started": "Corsi iniziati",           
    "filter_label_username_or_name": "Nome utente o nome",           
    "filter_label_groups": "Gruppi",           
    "filter_label_institutions": "Istituzioni",           
    "filter_label_roles": "Posizioni",           
    "filter_label_sign_up_codes": "Codici Registrazione",           
    "filter_placeholder_cohorts": "Sessioni Accademiche (tutto)",           
    "filter_placeholder_client_types": "Client (tutto)",           
    "filter_placeholder_compare": "Confronta (nessuno)",           
    "filter_placeholder_courses": "Corsi (tutto)",           
    "filter_placeholder_lessons": "Lezioni (tutto)",           
    "filter_placeholder_locale": "Località (tutto)",           
    "filter_placeholder_courses_completed": "Corsi Completati (tutto)",           
    "filter_placeholder_courses_started": "Corsi Iniziati (tutto)",           
    "filter_placeholder_text": "contiene…",           
    "filter_placeholder_groups": "Gruppo (tutto)",           
    "filter_placeholder_institutions": "Istituzione (tutto)",           
    "filter_placeholder_roles": "Posizione (tutto)",           
    "filter_placeholder_sign_up_codes": "Codice Registrazione (tutto)",           
    "in_progress": "In corso",           
    "last_seen": "Ultima visione",           
    "last_seen_x": "Ultima visione {{when}}",           
    "maintenance_mode_message": "<h2>I riepiloghi sono attualmente in corso di consueta manutenzione. <br> Per favore, riprova fra un po'! <br> <button ng-click=\"reload()\" class=\"flat coral hollow apply\">Aggiorna</button></h2>",           
    "mark_as_not_started": "SEGNA COME NON INIZIATO",           
    "reset_timer_time_left": "REIMPOSTA TIMER ({{msLeft | amDurationFormat}} left)",           
    "reset_timer_time_expired": "REIMPOSTA TIMER (Nessun tempo residuo)",           
    "reset_timer_timer_not_started": "TIMER NON PARTITO",           
    "no_report_loaded": "<h2>Seleziona le opzioni filtro in alto. <br> Fai clic su <button ng-click=\"reload()\" class=\"flat coral hollow apply\">Aggiorna</button> per caricare i dati.</h2>",           
    "no_results_found": "<h2>Nessun risultato trovato! <br> Regola i filtri o fai clic su <button ng-click=\"clear()\" class=\"flat beigebeyonddark hollow clear\">Cancella</button> per ricominciare.</h2>",           
    "no_results_found_short": "<h2>Nessun risultato trovato!</h2>",           
    "no_user_selected": "Nessun utente selezionato",           
    "overview": "Panoramica",           
    "pagination_limit": "(limite: {{limit}})",           
    "pagination_results_found": "{{num}} risultati trovati",           
    "quick_search_placeholder": "Filtra risultati…",           
    "quick_search_reset": "Reimposta Filtro",           
    "refresh": "Aggiorna",           
    "registered_x": "Registrato {{date}}",           
    "report_title_placeholder": "Riepilogo",           
    "report_title_active_users": "Utenti Attivi",           
    "report_title_users": "Utenti",           
    "report_title_player_lesson_sessions": "Sessioni Lezione",           
    "report_title_editor_lesson_sessions": "Sessioni Editor",           
    "report_title_time_on_task": "Tempo per Compito",           
    "reports_title": "RIEPILOGHI",           
    "report_y_axis_time_on_task_min": "Tempo per Compito (min.)",           
    "reset_course_confirm": "Questo reimposterà il corso affinché appaia allo studente come non ancora iniziato. Procedere?",           
    "reset_timer_confirm": "Questo reimposterà il timer dell'esame affinché lo studente possa aprirlo di nuovo. Procedere?",           
    "reset_at": "Reimposta",           
    "show_not_started": "MOSTRA CORSI NON ANCORA INIZIATI",           
    "started": "Iniziato",           
    "th_email": "Email",           
    "th_name": "Nome",           
    "th_registered": "Registrato",           
    "th_account_id": "Nome Utente",           
    "th_client_type": "Client",           
    "th_courses": "Corsi",           
    "th_course": "Corso",           
    "th_lesson": "Lezione",           
    "th_lessons": "Lezioni",           
    "th_courses_started": "Corsi iniziati",           
    "th_courses_completed": "Corsi completati",           
    "th_lessons_started": "Lezioni iniziate",           
    "th_lessons_completed": "Lezioni completate",           
    "th_status": "Stato",           
    "th_started": "Iniziato",           
    "th_completed": "Completato",           
    "th_total_time": "Tempo totale",           
    "th_assessment": "Punteggio Valutazione (media)",           
    "th_assess_score": "Punteggio Valutazione",           
    "th_assessment_group": "Valutazione",           
    "th_assessment_first": "Primo",           
    "th_assessment_best": "Migliore",           
    "th_assessment_score_first": "Primo Punteggio",           
    "th_assessment_score_best": "Miglior Punteggio",           
    "th_assessment_attempts_total": "# Completamenti",           
    "th_last_seen": "Ultima Visione",           
    "th_started_in_this_session": "Prima Sessione",           
    "th_completed_in_this_session": "Completata",           
    "th_save_count": "Salvataggi",           
    "total_time": "Tempo totale",           
    "to_use_user_report": "Per usare questo riepilogo, cerca gli utenti nel <strong>Riepilogo Attività</strong>, quindi fai clic sulla email di un utente per visualizzare qui i dettagli.",           
    "user": "Dettaglio Utente",           
    "view_activity_report": "VISUALIZZA RIEPILOGO ATTIVITÀ",           
    "x_available_courses": "{{num}} corsi disponibili",           
    "x_completed": "{{num}} completati",           
    "x_in_progress": "{{num}} in corso",           
    "status_started": "Iniziato",           
    "status_not_started": "Non iniziato",           
    "status_completed": "Completato",           
    "filter_placeholder_registered": "Registrato?",
    "lock": "SERRATURA",
    "unlock": "SBLOCCARE",
    "lock_course_confirm": "Questo bloccherà il corso in modo che l'utente non possa aprirlo. Procedere?",
    "unlock_course_confirm": "Questo sbloccherà il corso in modo che l'utente possa aprirlo. Procedere?",
    "authenticate": "AUTENTICARE",
    "authenticate_tooltip": "Ignora amministrativamente la verifica di supervisione per questo esame",
    "manually_authenticated": "AUTENTICATO MANUALMENTE",
    "manually_authenticate_stream_confirm": "Ciò consentirà all'utente di ignorare la verifica BioSig-ID per questo esame. Utilizzare dopo aver verificato il loro documento d'identità con foto. Procedere?"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.reports = window.locales.zh.reports || {};window.locales.zh.reports.reports = window.locales.zh.reports.reports || {};window.locales.zh.reports.reports = {...window.locales.zh.reports.reports, ...{
    "activity": "活动报告",           
    "apply": "申请",           
    "clear": "清除",           
    "completed": "已完成",           
    "completed_subtext": "已完成",           
    "course": "课程",           
    "course_activity": "课程活动",           
    "date_range_zoom_daily": "每天",           
    "date_range_zoom_monthly": "每月",           
    "date_range_zoom_weekly": "每周",           
    "date_range_interval_between": "之间",           
    "date_range_interval_last": "上一个",           
    "date_range_interval_since": "自从",           
    "date_range_label_between": "在 {{start}} 与 {{end}} 之间",           
    "date_range_label_last_day_plural": "最近 {{value}} 天",           
    "date_range_label_last_day_singular": "最近 {{value}} 天",           
    "date_range_label_last_week_plural": "最近 {{value}} 周",           
    "date_range_label_last_week_singular": "最近 {{value}} 周",           
    "date_range_label_last_month_plural": "最近 {{value}} 个月",           
    "date_range_label_last_month_singular": "最近 {{value}} 个月",           
    "date_range_label_since": "自从 {{start}}",           
    "date_range_unit_days": "天",           
    "date_range_unit_months": "个月",           
    "date_range_unit_weeks": "周",           
    "export_as_csv": "导出为 CSV",           
    "file_name_activity_report": "users_activity",           
    "file_name_course_activity": "user_courses_activity",           
    "filter_empty_courses": "（无课程）",           
    "filter_empty_courses_completed": "（无完成的课程）",           
    "filter_empty_courses_started": "（无开始的课程）",           
    "filter_empty_groups": "（无组）",           
    "filter_empty_institutions": "（无机构）",           
    "filter_empty_roles": "（无角色）",           
    "filter_empty_sign_up_codes": "（无注册代码）",           
    "filter_empty_unknown": "（无）",           
    "filter_group_by_group": "组",           
    "filter_group_by_institution": "机构",           
    "filter_group_by_role": "角色",           
    "filter_group_by_sign_up_code": "无注册代码",           
    "filter_label_cohorts": "同批人",           
    "filter_label_compare": "比较",           
    "filter_label_courses": "课程",           
    "filter_label_courses_completed": "已完成课程",           
    "filter_label_courses_started": "已开始课程",           
    "filter_label_groups": "组",           
    "filter_label_institutions": "机构",           
    "filter_label_roles": "角色",           
    "filter_label_sign_up_codes": "注册代码",           
    "filter_placeholder_cohorts": "同批人（全部）",           
    "filter_placeholder_compare": "比较（无）",           
    "filter_placeholder_courses": "课程（全部）",           
    "filter_placeholder_lessons": "课业（全部）",           
    "filter_placeholder_courses_completed": "已完成课程（全部）",           
    "filter_placeholder_courses_started": "已开始课程（全部）",           
    "filter_placeholder_groups": "组（全部）",           
    "filter_placeholder_institutions": "机构（全部）",           
    "filter_placeholder_roles": "角色（全部）",           
    "filter_placeholder_sign_up_codes": "注册代码（全部）",           
    "in_progress": "正在进行",           
    "last_seen": "最后看到",           
    "last_seen_x": "最后看到 {{when}}",           
    "maintenance_mode_message": "<h2>报告当前正在进行例行维护。<br>请稍后再试！<br> <button ng-click=\"reload()\" class=\"flat coral hollow apply\">刷新</button></h2>",           
    "no_report_loaded": "<h2>在上面选择筛选选项。<br>请单击<button ng-click=\"reload()\" class=\"flat coral hollow apply\">刷新</button>以加载数据。</h2>",           
    "no_results_found": "<h2>未找到任何结果！<br>请调整筛选条件或者单击<button ng-click=\"clear()\" class=\"flat beigebeyonddark hollow clear\">清除</button>以重新开始。</h2>",           
    "no_results_found_short": "<h2>未找到任何结果！</h2>",           
    "no_user_selected": "未选择任何用户",           
    "overview": "概览",           
    "pagination_results_found": "找到了 {{num}} 个结果。",           
    "quick_search_placeholder": "筛选结果...",           
    "quick_search_reset": "重置筛选条件",           
    "refresh": "刷新",           
    "registered_x": "注册时间 {{date}}",           
    "report_title_placeholder": "报告",           
    "report_title_active_users": "有效用户",           
    "report_title_users": "用户",           
    "report_title_time_on_task": "任务时间",           
    "reports_title": "报告",           
    "report_y_axis_time_on_task_min": "任务时间（分钟）",           
    "show_not_started": "显示未开始的课程",           
    "started": "已开始",           
    "th_email": "电子邮件",           
    "th_name": "姓名",           
    "th_registered": "注册时间",           
    "th_courses": "课程",           
    "th_course": "课程",           
    "th_lesson": "课业",           
    "th_lessons": "课业",           
    "th_courses_started": "已开始课程",           
    "th_courses_completed": "已完成课程",           
    "th_lessons_started": "已开始课业",           
    "th_lessons_completed": "已完成课业",           
    "th_status": "状态",           
    "th_started": "已开始",           
    "th_completed": "已完成",           
    "th_total_time": "总时间",           
    "th_assessment": "评估得分（平均）",           
    "th_assess_score": "评估得分",           
    "th_last_seen": "最后看到",           
    "total_time": "总时间",           
    "to_use_user_report": "要使用此报告，请在<strong>活动报告</strong>中搜索用户，然后单击用户的电子邮件在此处查看其详细信息。",           
    "user": "用户详细信息",           
    "view_activity_report": "查看活动报告",           
    "x_available_courses": "{{num}} 个可用课程",           
    "x_completed": "{{num}} 个已完成",           
    "x_in_progress": "{{num}} 个正在进行中",           
    "status_started": "已开始",           
    "status_not_started": "未开始",           
    "status_completed": "已完成",           
    "date_range_label_show_all": "全部显示",           
    "report_title_player_lesson_sessions": "课时",           
    "th_started_in_this_session": "第一课时",           
    "th_completed_in_this_session": "已完成",           
    "filter_placeholder_locale": "区域设置（全部）",           
    "filter_empty_playlists_completed": "（没有完成任何播放列表）",           
    "filter_empty_playlists_started": "（没有开始任何播放列表）",           
    "filter_placeholder_playlists_started": "播放列表已经开始（所有）",           
    "filter_placeholder_playlists_completed": "播放列表已经完成（所有）",           
    "report_title_editor_lesson_sessions": "编辑时段",           
    "th_playlists": "播放列表",           
    "th_save_count": "保存",           
    "th_assessment_group": "评估",           
    "th_assessment_first": "第一",           
    "th_assessment_best": "最佳",           
    "th_assessment_score_first": "第一名的得分",           
    "th_assessment_score_last": "最佳一名的得分",
    "th_assessment_attempts_total": "# 完成",           
    "filter_label_username_or_name": "用户名或姓名",           
    "filter_placeholder_text": "包含...",           
    "th_account_id": "用户名",           
    "filter_placeholder_client_types": "客户端（全部）",           
    "th_client_type": "客户端",           
    "mark_as_not_started": "标记为未启动",           
    "reset_course_confirm": "这会将课程重置到尚未开始的状态。是否继续？",           
    "reset_at": "重置",           
    "pagination_limit": "（限时：{{limit}}）",           
    "th_assessment_score_best": "最佳得分",           
    "reset_timer_time_left": "重置计时器（{{msLeft | amDurationFormat}}剩余）",           
    "reset_timer_time_expired": "重置计时器（无剩余时间）",           
    "reset_timer_timer_not_started": "计时器未开启",           
    "reset_timer_confirm": "这将重置测验计时器，以便用户可再次使用。继续？",           
    "filter_placeholder_registered": "已注册？",           
    "lock": "锁",
    "unlock": "开锁",
    "lock_course_confirm": "这将锁定课程，因此用户无法打开它。继续？",
    "unlock_course_confirm": "这将解锁课程，以便用户可以打开它。继续？",
    "authenticate": "认证",
    "authenticate_tooltip": "行政上覆盖本次考试的监考验证",
    "manually_authenticated": "手动验证",
    "manually_authenticate_stream_confirm": "这将允许用户绕过本次考试的 BioSig-ID 验证。当您验证了他们带照片的身份证件后使用。继续？"
}
};