import i18n from 'i18next';
import ICU from 'i18next-icu';
import { initReactI18next } from 'react-i18next';
import { SupportedLocales } from 'Translation';

export function initializeReactI18N() {
    i18n
        // enable ICU format for interpolation
        .use(ICU)
        // pass the i18n instance to react-i18next.
        .use(initReactI18next)
        // for all init options read: https://www.i18next.com/overview/configuration-options
        .init({
            fallbackLng: 'en',
            defaultNS: 'back_royal',
            ns: 'back_royal',
            interpolation: {
                escapeValue: false, // not needed for react as it escapes by default
            },
        });

    SupportedLocales.forEach(locale => {
        i18n.addResourceBundle(locale, 'back_royal', window.locales[locale]);
    });
}

export function initializeStubbedReactI18N() {
    i18n
        // enable ICU format for interpolation
        .use(ICU)
        // pass the i18n instance to react-i18next.
        .use(initReactI18next)
        // for all init options read: https://www.i18next.com/overview/configuration-options
        .init({
            fallbackLng: 'en',
            defaultNS: 'back_royal',
            ns: 'back_royal',
            react: {
                useSuspense: false,
            },
            interpolation: {
                escapeValue: false, // not needed for react as it escapes by default
            },
        });
}

export default i18n;
